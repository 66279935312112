@import './mixins/aspect-ratio';

html, body, #root {
  margin: 0;
  padding: 0;
  background-color: #070908;
  width:100%;
  height: 100%;
}

#root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    object-fit: cover;
  }

  .hotspot {
      padding: 0;
      margin: 0;
      list-style: none;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row;
      justify-content: space-around;
      line-height:30px;
  }

  .hotspot-container {
      color: white;
      font-weight: bold;
      text-align: center;
      flex: 1 0 auto;
      height:auto;
      width: 100%;

      img {
        width: 100%;
      }
  }

  .hotspot-container:before {
      content:'';
      float:left;
      padding-top:100%;
  }

    ul {
      margin: 0 0 10px 0;
    }

    video, .app {
      width: 100%;
      height: 100%;
    }

    .transition-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
      width: 100%;
      height: 100%;

      &.transition-container-show {
        display: block;
      }
    }

    .app {
      position: relative;
      z-index: 1;
    }

    .ticker-container {
      position: absolute;
      bottom: 3%;
      left: 0;
      width: 100%;
      height: 4.5%;
      background-color: #636566;
      z-index: 5;
      text-transform: uppercase;
      color: #FFFFFF;
      font-family: sans-serif;

      p {
        white-space: nowrap;
      }
    }

    .back {
      pointer-events: all;
      width: 100%;
      height: 100%;
    }

  .overlay {
    position: absolute;
    z-index: 4;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;

    .page {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .content {
        width: 100%;
        height: 100%;
      }

      img.background, img.content {
        position: absolute;
        top: 0;
        left: 0;
      }

      img.content {
        z-index: 2;
      }
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms linear;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear;
  }
}